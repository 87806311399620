<template>
<footer class="footer">
	<div class="floating_btn mainFloating float_priceWrap" ref="floating_btn">
		<ul>
			<li class="price" v-show="this.$route.fullPath === '/goSvcOrd000'">
                <p>예상금액</p>
                <p><span>{{totAmt | comma}}</span>원</p>
            </li>
			<li><a href="javascript:;"><span>사이트맵</span></a></li>
			<li><a @click="$router.push('/goSvcOrd000')"><span>온라인견적/신청</span></a></li>
			<li class="consulting"><a @click="$router.push('/goSupCns000')"><span>컨설팅 신청</span></a></li>
			<li class="chat"><a v-on:click="chat()"><span>챗봇</span></a></li>
		</ul>
        <div class="float_moreeWrap">
            <button type="button" class="btn_float_more">닫힘</button>
            <button type="button" class="top">최상단으로</button>
        </div>
	</div>
	<div class="innWrap">
		<div class="foot-link">
			<ul class="link bar">
				<li><a href="https://corp.kt.com/" target="_blank" title="새창">회사소개</a></li>
				<li><a href="https://corp.kt.com/" target="_blank" title="새창">About KT</a></li>
				<li><a href="https://corp.kt.com/eng/" target="_blank" title="새창">Global Biz</a></li>
				<li><a href="https://corp.kt.com/html/etc/agreement_01.html" target="_blank" title="새창">이용약관</a></li>
				<li><a href="https://corp.kt.com/html/etc/legal.html" target="_blank" title="새창">법적고지</a></li>
				<li><a href="https://corp.kt.com/html/etc/privacy.html" target="_blank" style="color:#222;">개인정보처리방침</a></li>
				<li><a href="https://corp.kt.com/html/etc/agreement_05.html" target="_blank" title="새창">청소년 보호정책</a></li>
				<li><a href="https://corp.kt.com/html/etc/contact.html" target="_blank" title="새창">문의/안내처</a></li>
				<li><a href="https://inside.kt.com/html/accessibility/webHelp_01.html" target="_blank" >웹접근성 도움말</a></li>
				<li><a href="/gigaofficefile/kt_gigaoffice_opensource_notice.htm" target="_blank" >오픈소스 라이선스 고지</a></li>
			</ul>
			<div class="group-info">
				<a href="https://corp.kt.com/html/intro/group/ict_01.html" target="_blank" title="새창열림">그룹사 소개</a>
			</div>
		</div>
	</div>
	<div class="foot-mark-wrap">
		<div class="innWrap">
			<div class="address-info">
				<p class="address">
					(주)케이티 대표이사 김영섭 경기도 성남시 분당구 불정로 90 (정자동) 사업자등록번호 : 102-81-42945 통신판매업신고 : 2002-경기성남-0048 <a href="#" onclick="window.open('http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=1028142945 ', 'windowPop', 'width=750, height=700, left=500, top=350, resizable = yes')" title="새창열림">사업자정보확인</a>
				</p>
				<p class="service">
						고객센터 : [모바일] 휴대폰+114(무료), 1588-0010(유료) [인터넷/TV/전화] 유선전화(국번없이) 100, 휴대폰(지역번호)+100
				</p>
				<p class="copyright">
					Copyrightⓒ 2022 kt corp. All rights reserved.
				</p>
			</div>
			<div class="sns-info">
				<a href="https://www.facebook.com/kt.corp" target="_blank" title="KT 페이스북 새창열림" class="sns-facebook"></a>
				<a href="https://twitter.com/kt_corp" target="_blank" title="KT 트위터 새창열림" class="sns-twitter"></a>
				<a href="https://blog.naver.com/ktenterprise" target="_blank" title="KT Enterprise 블로그 새창열림" class="sns-blog"></a>
				<a href="https://youtube.com/c/KTBiz" target="_blank" title="KT 유튜브 새창열림" class="sns-youtube"></a>
				<a href="https://www.instagram.com/kt.corp" target="_blank" title="인스타그램 새창열림" class="sns-Instagram"></a>
				<a href="https://post.naver.com/my.nhn?memberNo=30305360" target="_blank" title="KT 네이버 포스트 새창열림" class="sns-post"></a>
			</div>
		</div>
	</div>
</footer>
</template>
<script>
import $ from 'jquery'

export default {
	name: 'footerLayout',
	props: {
		totAmt: {
			type: Number,
			default: 0
		}
	},
    data () {
		return {
		}
	},
    mounted: function() {
		$(document).ready(function(){
			//floating_btn
			if($('.floating_btn').length > 0) {
				// const floatScl = $('.floating_btn').scrollTop();
				$('.floating_btn .top').click(function(e){
					e.preventDefault();
					$('html, body').animate({scrollTop: 0}, 500);
				})
				$(window).scroll(function() {
					const position = $(window).scrollTop();
					// if(position === 0) { 
					// 	$('.header').addClass('fix');
					// } else {
					// 	$('.header').removeClass('fix');
					// }
					if(position > 100){
						$('.floating_btn .top').addClass('on');
					}else{
						$('.floating_btn .top').removeClass('on');
					}
				})
			}
		})
    },
	created: function () {
	},
    destroyed: function () {
	},
	components: {
	},
	methods: {
		chat () {
			const url = process.env.VUE_APP_CHAT_URL
			window.open(url, 'chatbot', 'width=420,height=670')
		}
	}
}
</script>



