<template>
<body class="">
    <div class="loginWrap slide-in-bottom" style="">
        <div class="loginTit">법인고객 로그인</div>
        <div>
            <div class="loginInput">
                <input type="text" name="userId" id="userId" placeholder="아이디" v-model="userId" class="input-line" @keyup.enter="login">
                <input type="password" name="password" id="password" placeholder="비밀번호" v-model="password" autocomplete="off" @keyup.enter="login" class="input-line mgt20 bglogin">
            </div>

            <div class="findPsnInfo">
                <div class="mySaveId checkbox">
					<!-- <a class="checkbox login">아이디 저장</a> -->
                    <input type="checkbox" id="chkLoginSaved" class="hide" v-model="chkLoginSaved">
                    <label for="chkLoginSaved" @click="chkLogin()">아이디 저장</label>
                </div>
                <div class="findIdPw">
                    <a @click="link('https://login.kt.com/wamui/CusFindIdCorpMain.do')">아이디 찾기</a>
                    <span class="line"></span>
                    <a @click="link('https://login.kt.com/wamui/CusFindPasswordCorpMain.do')">비밀번호 찾기</a>
                </div>
            </div>
            <div class="btn-login">
                <a @click="login" :class="loginBtn" :disabled="loginBtnDisabled">로그인</a>
                <!-- a @click="link('https://login.kt.com/wamui/MemSelectMemberType.do?domainNameCd=2')" class="btn-md01 btn-gray">회원가입</a -->
                <a @click="link('https://accounts.kt.com/wamui/MemSelectMemberType.do?domainNameCd=2')" class="btn-md01 btn-gray">회원가입</a>
                <a @click="closePopup" class="btn-md01 btn-gray">닫기</a>
            </div>
        </div>
    </div>
</body>
</template>
<script>
import { gigaofficeService } from 'Api'

export default {
	components: {
		// HeaderLayout,
		// FooterLayout,
	},
	props: {
		token: {
			type: String,
			default: ''
		}
	},
	data () {
		return {
			userId: '',
			password: '',
			capUrl: '',
			capHtml: '',
			capInsid: '',
			captSoundUrl: '',
			chkLoginSaved: '',
			loginBtnDisabled: false,
			loginBtn: 'btn-md01 btn-primary',
			capsFlag: '',
			flag: false,
			verify: '',
			ip: '',
			captchaFlag: '',
		}
	},
	mounted: async function () {
		this.getId()
		if(localStorage.getItem("save_id")) {
			this.chkLoginSaved = true
		}
	},
	methods: {
		async recaptcha() {
			await this.$recaptchaLoaded().then(() => {
				this.$recaptcha('login').then((token) => {
					this.token = token
					this.verify = token
				})
			})
		},
		link: function (url) {
			window.open(url, 'target=_blank')
		},
		chkLogin() {
			this.chkLoginSaved = !this.chkLoginSaved
		},
		closePopup() {
			this.$store.commit('CLOSE_LOGIN_POP')
			this.$store.commit('CLOSE_DIM')
			// scroll lock 해제
			const body = document.querySelector('body')
			body.style.removeProperty('overflow')
			body.style.removeProperty('position')
			body.style.removeProperty('top')
			body.style.removeProperty('width')
			// window.scrollTo(0, window.pageYOffset)
		},
		async login() {
			if(this.userId == '' || this.userId == null) {
				alert('아이디를 입력하여 주십시오.') 
				return
			}
			if(this.password == '' || this.password == null) {
				alert('비밀번호를 입력하여 주십시오.')
				return
			}
			this.verify = this.token
			if(this.$store.state.Captcha) {
				if (this.verify == '' || this.verify == null) {
					// alert("reCAPTCHA token이 없습니다. 잠시 후 다시 접속해주시기 바랍니다.")
					// console.log("reCAPTCHA token이 없습니다. 잠시 후 다시 접속해주시기 바랍니다.")
					// return false
				}
			//예외일 때
			} else {
				this.verify = 'exceptIp'
			}
			
			this.loginBtnDisabled = true
			this.loginBtn = 'btn-md01 btn-gray'

			let rsaParam = {
			}
			const rsaRes = await this.$store.dispatch("auth/getAuthRsa", rsaParam)
			let publicKeyExponent = rsaRes.data.response.publicKeyExponent
			let publicKeyModulus = rsaRes.data.response.publicKeyModulus
			let seq = rsaRes.data.response.seq

			// eslint-disable-next-line
			var rsa = new RSAKey();
			rsa.setPublic(publicKeyModulus, publicKeyExponent)

			let rsaId = rsa.encrypt(this.userId)
			let rsaPw = rsa.encrypt(this.password)

			let param = {
				id : rsaId
				, pw : rsaPw
				, verify : this.verify
				, seq : seq
			}

			await this.$store.dispatch("auth/login", param).then(res => {
				this.saveid()
				this.$router.push('/')
			}).catch(error => {
				// console.error(error);
				this.loginBtnDisabled = false
				this.loginBtn = 'btn-md01 btn-primary'
			});
		},
		saveid() {
			var expdate = new Date()
			if(this.chkLoginSaved) {
				expdate.setTime(expdate.getTime() + 1000*3600*24*30) //쿠키 만료기간 30일
				localStorage.setItem("save_id", this.userId)
				localStorage.setItem("id_expdate", expdate)
			} else {
				localStorage.removeItem("save_id")
				localStorage.removeItem("id_expdate")
			}
		},
		getId() {
			this.userId = localStorage.getItem("save_id")
			this.chkLoginSaved = this.userId != null ? true : false
		}
  }
}
</script>
<style>
.slide-in-bottom {
	opacity: 1;
	z-index:100;
}
</style>