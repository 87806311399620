<template>
  <header class="header fix" v-smooth-scroll>
    <div class="dimm-layer" v-show="$store.state.Dimmed"></div>
    <transition name="slide">
      <loginLayer v-show="$store.state.LoginLayer" :token="token"></loginLayer>
    </transition>
    <div class="utils">
      <div class="innWrap">
        <ul>
          <!-- <li><a @click="$router.push('/goLogin001')" >로그인</a></li> -->
          <li v-if="!this.storageToken"><a @click="loginPop">로그인</a></li>
          <!-- li v-if="!this.storageToken"><a @click="link('https://login.kt.com/wamui/MemSelectMemberType.do?domainNameCd=2')">회원가입</a></li-->
          <li v-if="!this.storageToken"><a @click="link('https://accounts.kt.com/wamui/MemSelectMemberType.do?domainNameCd=2')">회원가입</a></li>
          <li><a @click="link('https://biz.kt.com/')">소상공인</a></li>
          <li><a @click="link('https://www.kt.com/')">개인</a></li>
          <li><a @click="link('https://enterprise.kt.com/main.jsp')">기업</a></li>
          <li><a @click="link('https://b2bcare.kt.co.kr')">관제포털</a></li>
          <li><a @click="logout" v-if="this.storageToken" >로그아웃</a></li>
        </ul>
      </div>
    </div>
    <div class="innWrap menu">
      <h1 class="logo"><a @click="$router.push('/')">Flexon</a></h1>
      <nav class="gnb">
        <ul>
          <li class="mgl30">
            <a class="depth1">MY <span></span></a>
            <ul class="mega-dropdown" style="left: -102px">
              <li class="row">
                <ul class="mega-col">
                  <a href="#" @click="$router.push('/goMyBiz000')" class="depth2"
                    >가입상품 관리</a
                  >
                  <li>
                    <a href="#" @click="$router.push('/goMyBiz000')"
                      >가입 정보</a
                    >
                  </li>
                </ul>
                <ul class="mega-col">
                  <a href="#" class="depth2">요금/결제정보</a>
                  <li v-if="this.menuFlag=='01'"><a href="#" @click="$router.push('/goMyFee000')">요금 현황</a></li>
                </ul>
                <ul class="mega-col">
                  <a class="depth2">서비스 상태</a>
                  <li>
                    <a @click="$router.push('/goMySvc000')">트래픽 현황</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="mgl30">
            <a href="#" class="depth1">PRODUCTS<span></span></a>
            <ul class="mega-dropdown">
              <li class="row">
                <ul class="mega-col">
                  <a href="#" @click="$router.push('/goSvcPod000')" class="depth2"
                    >기업 인터넷</a
                  >
                  <li>
                    <a href="#" @click="$router.push('/goSvcPod000')">서비스 소개</a>
                  </li>
                </ul>
                <ul class="mega-col">
                  <a href="#" @click="$router.push('/goSvcPod100')" class="depth2"
                    >Flexline</a
                  >
                  <li>
                    <a href="#" @click="$router.push('/goSvcPod100')">서비스 소개</a>
                  </li>
                </ul>
                <ul class="mega-col">
                  <a href="#" @click="$router.push('/goSvcPod200')" class="depth2"
                    >GiGAoffice</a
                  >
                  <li>
                    <a href="#" @click="$router.push('/goSvcPod200')">서비스 소개</a>
                  </li>
                  <li>
                    <a href="#" @click="$router.push('/goSvcPod201')">고객 사례</a>
                  </li>
                  <li>
                    <a href="#" @click="$router.push('/goSvcPod202')">센터 안내</a>
                  </li>
                </ul>
                <ul class="mega-col">
                  <a href="#" @click="$router.push('/goSvcPod300')" class="depth2"
                    >Kornet</a
                  >
                  <li>
                    <a href="#" @click="$router.push('/goSvcPod300')">서비스 소개</a>
                  </li>
                  <li>
                    <a href="#" @click="$router.push('/goSvcPod301')">고객 사례</a>
                  </li>
                </ul>
                <ul class="mega-col">
                  <a href="#" @click="$router.push('/goSvcPod400')" class="depth2"
                    >VPN</a
                  >
                  <li>
                    <a href="#" @click="$router.push('/goSvcPod400')">서비스 소개</a>
                  </li>
                  <li>
                    <a href="#" @click="$router.push('/goSvcPod401')">고객 사례</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="mgl30">
            <a href="#" class="depth1">ORDER<span></span></a>
            <ul class="mega-dropdown transformX50">
              <li class="row">
                <ul class="mega-col">
                  <a href="#" @click="$router.push('/goSvcOrd000')" class="depth2"
                    >서비스 신청</a
                  >
                  <li><a href="#" @click="$router.push('/goSvcOrd000')">가입</a></li>
                  <li><a href="#" @click="$router.push('/goSvcOrd003')">변경</a></li>
                  <!-- <li><a href="#" @click="$router.push('/goSvcOrd003')">변경_기존</a></li> -->
                  <!-- <li><a href="#" @click="$router.push('/goSvcAs000')">A/S</a></li> -->
                </ul>
                <ul class="mega-col">
                  <a href="#" class="depth2">신청 현황</a>
                  <li>
                    <a href="#" @click="$router.push('/goSvcSta000')">신청 현황</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="mgl30">
            <a href="#" class="depth1">SUPPORT<span></span></a>
            <ul class="mega-dropdown" style="left: 260px">
              <li class="row">
                <ul class="mega-col">
                  <a href="#" @click="$router.push('/goSupCns000')" class="depth2"
                    >서비스 상담</a
                  >
                  <li>
                    <a href="#" @click="$router.push('/goSupCns000')">상담 신청</a>
                  </li>
                </ul>
                <!-- ul class="mega-col">
                  <a
                    href="#"
                    class="depth2"
                    >상품 추천</a
                  >
                  <<li>
                    <a href="#" @click="$router.push('/goSupPrd000')"
                      >내게 맞는 상품</a
                    >
                  </li>>
                </ul -->
                <ul class="mega-col">
                  <a
                    href="#"
                    @click="$router.push('/goSupApl000')"
                    class="depth2"
                    >자료실</a
                  >
                  <li>
                    <a href="#" @click="$router.push('/goSupApl000')"
                      >신청서/브로셔</a
                    >
                  </li>
                  <li>
                    <a href="#" @click="$router.push('/goSupMov000')">동영상</a>
                  </li>
                </ul>
                <ul class="mega-col">
                  <a
                    href="#"
                    @click="$router.push('/goSupFaq000')"
                    class="depth2"
                    >자주하는 질문</a
                  >
                </ul>
                <ul class="mega-col">
                  <a href="#" @click="$router.push('/goSupNot000')" class="depth2">공지사항</a>
                  <li>
                    <a href="#" @click="$router.push('/goSupNot000')">공지사항</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="try"><a @click="$router.push('/goSvcOrd000')">서비스 신청</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import common from "@/assets/js/common";
import { gigaofficeService } from "Api";
import date from "date-and-time";
import loginLayer from "@/views/gigaoffice/goLogin001";

// var easeScroll = require("@/assets/js/jquery.easeScroll.js");
export default {
  name: "headerLayout",
  props: ["activeNum"],
  components: { loginLayer },
  data () {
    return {
      storageToken: "",
      storageUserInfo: "",
      partyTypeId: "",
      userName: "",
      userId: "",
      token: '',
      menuFlag: '',
      ipFlag: false
    };
  },
  updated: async function() {
    
    },
  mounted: async function () {
    setTimeout(() => {
      this.getIp()
      setTimeout(() => {
        this.menuOnoff()
      }, 100);
      // this.menuOnoff()
    }, 800);
    // common.navOn()
    //예외IP확인
    // if(this.ipFlag) {
    //   await this.recaptcha()
    // }
    $(document).ready(function () {
      /* easeScroll */
      // easeScroll.easeScroll = $("html").easeScroll({
      //   frameRate: 60,
      //   animationTime: 1000,
      //   stepSize: 60,
      //   arrowScroll: 50,
      // });
      /* goMyBiz000: 조회 selectBox 범위 밖 클릭 시 close */
      $("html").click(function (e) {
        if ($(e.target).parents(".select").length < 1) {
          $(".select.is-open").not(parent).removeClass("is-open");
        }
      });
      /* goMyBiz000: 조회 selectBox open close */
      $(".select")
        .on("click", ".placeholder", function () {
          var parent = $(this).closest(".select");

          if (!parent.hasClass("is-open")) {
            parent.addClass("is-open");
            $(".select.is-open").not(parent).removeClass("is-open");
          } else {
            parent.removeClass("is-open");
          }
        })
        .on("click", "ul>li", function () {
          var parent = $(this).closest(".select");
          parent
            .removeClass("is-open")
            .find(".placeholder")
            .text($(this).text());
        });
    });
    // localStorage
    if (localStorage.getItem("accessToken")) {
      sessionStorage.setItem(
        "accessToken",
        localStorage.getItem("accessToken")
      );
      // sessionStorage.setItem('expiration', localStorage.getItem('expiration'))
      sessionStorage.setItem(
        "partyTypeId",
        localStorage.getItem("partyTypeId")
      );
      sessionStorage.setItem("userName", localStorage.getItem("userName"));
      sessionStorage.setItem("userId", localStorage.getItem("userId"));
      sessionStorage.setItem("userEmail", localStorage.getItem("userEmail"));
      sessionStorage.setItem(
        "phoneNumber",
        localStorage.getItem("phoneNumber")
      );
      sessionStorage.setItem(
        "mobileNumber",
        localStorage.getItem("mobileNumber")
      );
      sessionStorage.setItem(
        "expirationTime",
        date.format(date.addHours(new Date(), 2), "YYYY-MM-DD HH:mm:ss")
      );

      localStorage.removeItem("accessToken");
      localStorage.removeItem("expriation");
      localStorage.removeItem("partyTypeId");
      localStorage.removeItem("userName");
      localStorage.removeItem("userId");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("phoneNumber");
      // localStorage.clear()
    }
    if (!sessionStorage.getItem("ip")) {
      await this.getIpAddress();
    }

    this.storageToken = sessionStorage.getItem("accessToken");
    this.userInfo = sessionStorage.getItem("userInfo");
    this.partyTypeId = sessionStorage.getItem("partyTypeId");
    this.userName = sessionStorage.getItem("userName");
    this.userId = sessionStorage.getItem("userId");
  },
  created: function () {
    window.addEventListener("scroll", this.navOn);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.navOn);
  },
  methods: {
    async getIp() {
      let payload = {group_cd: "IP", code_cd: sessionStorage.getItem('ip')}
      let res = await gigaofficeService.getComCodeList(payload);
      var length = res.data.response.results.length
      if(length == 0) {
        // console.log('예외X')
        // 20230627 RECAPTCHA 예외
        this.$store.commit('CAPTCHA_TRUE')
        this.ipFlag = true
        await this.recaptcha()
        // 20230627 RECAPTCHA 예외
        // console.log('CAPTCHA_FALSE 예외')
        // this.$store.commit('CAPTCHA_FALSE')
        // this.ipFlag = false
        } else {
        // console.log('예외IP')
        this.$store.commit('CAPTCHA_FALSE')
        this.ipFlag = false
      }
    },
    link: function (url) {
      window.open(url, 'target=_blank')
    },
    async recaptcha() {
      console.log('recaptcha connecting...')
      await this.$recaptchaLoaded().then(() => {
        console.log('recaptcha Loaded')
        this.$recaptcha('login').then((token) => {
          this.token = token
          // console.log(token)
          // this.$store.commit('CAPTCHA_TOKEN', token)
        })
      })
      // await this.$recaptchaLoaded()
      // const token = await this.$recaptcha('login')
    },
    menuOnoff: async function () {
			let payload = {
				group_cd : 'PG',
			}
			await this.$store.dispatch('gigaoffice/getComCodeList', payload).then(res => {
				this.menuFlag = res.data.response.results[0].code_cd
        if(this.$router.currentRoute.fullPath == '/goMyFee000' && this.menuFlag == '02') {
          alert('이용할 수 없는 메뉴입니다. 메인화면으로 이동합니다.')
          this.$router.push("/");
        }
			})
			.catch(err => {
				console.log(err)
			})
		},
    async loginPop() {
      common.loginPop();
    },
    logout() {
      if (confirm("로그아웃 하시겠습니까?")) {
        let param = {
        //   user_id: sessionStorage.getItem("userId"),
        };
        this.$store
          .dispatch("auth/logout", param)
          .then((res) => {
            this.$router.push("/");
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        return;
      }
    },
    getOffset(el) {
      var _x = 0;
      var _y = 0;
      while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
      }
      return { top: _y, left: _x };
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.lastScrollPosition = currentScrollPosition;
      // console.log(this.lastScrollPosition)
    },
    navOn() {
      var nowTop = document.documentElement.scrollTop;
      var headerHeight = document.querySelector("header").offsetHeight + 200;

      // 2. 각 섹션의 offset().top 값을 가져온다.
      // 2-1. 고정된 header가 있는 경우 header의 높이 만큼 뺀다.
      var sec1_offset =
        this.getOffset(document.getElementById("section-01")).top - 70;
      var sec2_offset =
        this.getOffset(document.getElementById("section-02")).top - 70;
      var sec3_offset =
        this.getOffset(document.getElementById("section-03")).top - 70;
      // 3. class를 초기화 시킨다.
      // 4. 현재 scrollTop()의 값이 각 섹션의 범위에 있으면 class를 추가한다.
      if (nowTop >= 0 && nowTop < sec2_offset) {
        this.updateActiveNum(1);
      } else if (nowTop >= sec2_offset && nowTop < sec3_offset) {
        this.updateActiveNum(2);
      } else if (nowTop >= sec3_offset) {
        this.updateActiveNum(3);
      }
    },
    updateActiveNum(num) {
      this.$emit("updateActiveNum", num);
    },
    getIpAddress: async function () {
      try {
        const res = await this.$store.dispatch("auth/getIpAddress");
        sessionStorage.setItem("ip", res.data.ip_addr);
      } catch (err) {
        this.$store.commit("END_SPINNER");
        console.log(err);
      }
    },
  },
};
</script>
<style>
.dimm-layer {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  background: #575188;
  /* filter: alpha(opacity=50); */
}
.slide-enter {
  transform: translateY(10px);
  opacity: 0.5;
}
.slide-enter-active {
  transition: 0.3s ease;
}
.slide-leave-active {
  transition: 0.3s ease;
}
.slide-leave-to {
  /* transform: translateY(10px); */
  opacity: 0.2;
}
</style>
